import React, { useState, useEffect } from 'react';
import './Panels.css';
import Panels from '../../components/panels/Panels';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPanelsByLabbThunk } from '../../store/panels/panelsSlice';
import PasswordProtect from '../../components/lab_fusion/password_protect/PasswordProtect';
import LabFusionLogo from '../../assets/logo/LabFusionLogo.png';

const PanelsPage = () => {
    const dispatch = useDispatch();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const panelsData = useSelector(state => state.panels.panels);

    useEffect(() => {
        dispatch(fetchPanelsByLabbThunk());
    }, [dispatch]);

    const handleAccessGranted = () => {
        setIsAuthorized(true);
    };

    if (!isAuthorized) {
        return <PasswordProtect onAccessGranted={handleAccessGranted} />;
    }

    return (
        <div className="panels-page-container">
            <header className="panels-header">
                <img
                    className="panels-logo"
                    src={LabFusionLogo}
                    alt="Client Logo"
                />
            </header>
            <div className="panels-body-container">
                <div className="panels-content-container">
                    <div className="panels-table-container">
                        <Panels data={panelsData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PanelsPage;
