import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Badganator.css';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { GenericIcons } from "../../assets/icons";
import { IconButton } from "../buttons/icon_button/IconButton";
import { createCollectorBadge } from "../../store/badganator/badganatorSlice";
import { showMessageModal } from "../../store/modals/modalsSlice";
import {fetchClientScreenersDetails} from "../../store/screener/screenerSlice";

const Badganator = ({ closeModal }) => {
    const dispatch = useDispatch();
    const [selectCollectorOptions, setSelectCollectorOptions] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [shouldDownload, setShouldDownload] = useState(false);
    const [collectorBadgeUrl, setCollectorBadgeUrl] = useState("");

    const clientId = useSelector(state => state.badganator.clientId);
    const clientCollectors = useSelector(state => state.screeners.screeners);

    useEffect(() => {
        if (clientId) {
            dispatch(fetchClientScreenersDetails({ clientId }));
        }
    }, [clientId, dispatch]);

    const handleCollectorChange = (selectedOptions) => {
        setSelectCollectorOptions(selectedOptions);
    };

    const submitCollectorOptions = async () => {
        const collectorId = selectCollectorOptions.value;
        const formData = new FormData();
        formData.append('collector_id', collectorId);
        try {
            const response = await dispatch(createCollectorBadge({ formData })).unwrap();
            setCollectorBadgeUrl(response[0]); // Assuming the response has a url property
            setShouldDownload(true);
        } catch (error) {
            console.error('Failed to create badge:', error);
            dispatch(showMessageModal({ message: "Failed to create badge.", link: null }));
        }
    };

    const downloadCollectorBadge = (e) => {
        e.preventDefault();
        dispatch(showMessageModal({ message: "Download Initiated.", link: null }));
        if (!selectCollectorOptions.value) {
            dispatch(showMessageModal({ message: "No collector selected.", link: null }));
            return;
        }
        submitCollectorOptions();
    };

    useEffect(() => {
        if (collectorBadgeUrl && shouldDownload) {
            const anchor = document.createElement('a');
            anchor.href = collectorBadgeUrl;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            setCollectorBadgeUrl(""); // Reset URL
            setShouldDownload(false);
            dispatch(showMessageModal({ message: "Download successful.", link: null }));
        }
    }, [collectorBadgeUrl, shouldDownload]);

    const collectorOptions = clientCollectors.map(clientCollector => ({
        value: clientCollector.collector_id,
        label: `${clientCollector.collector_first_name} ${clientCollector.collector_last_name}`,
    }));

    return (
        <div className="badganator-main-frame">
            {loadingTable ? null :
                <div className="badganator-header">
                    <div className="badganator-collector-selector">
                        <CreatableSelect
                            components={makeAnimated()}
                            placeholder="Select User..."
                            className="multi-selector-user"
                            closeMenuOnSelect={true}
                            options={collectorOptions}
                            onChange={handleCollectorChange}
                            value={selectCollectorOptions}
                        />
                    </div>
                    <div className="pre-set-report-icon-frame">
                        {selectCollectorOptions && Object.keys(selectCollectorOptions).length > 0 &&
                            <IconButton onClick={(e) => downloadCollectorBadge(e)} hoverIcon={GenericIcons.DownloadIcon} defaultIcon={GenericIcons.DownloadIcon} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Badganator;
