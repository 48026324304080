// freddy-react/src/store/products/lssProductsSlice.js

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchShopifyProducts} from "../../services/api/v1/productsService";


export const getShopifyProducts = createAsyncThunk(
    'shopifyProducts/getShopifyProducts',
    async ({ accessToken, shopifyAdminAccessToken }, thunkAPI) => {
        return await fetchShopifyProducts({accessToken, shopifyAdminAccessToken});
    }
);



export const shopifyProductsSlice = createSlice({
    name: 'shopifyProducts',
    initialState: {
        allShopifyProducts: [],
        isLoading: false,
    },
    reducers: {
        setShopifyProducts: (state, action) => {
            state.allShopifyProducts = action.payload;
            state.isLoading = false;
        },
        clearShopifyProducts: (state) => {
            state.allShopifyProducts = [];
            state.isLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getShopifyProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getShopifyProducts.fulfilled, (state, action) => {
                state.allShopifyProducts = action.payload;
                state.isLoading = false;
            })
            .addCase(getShopifyProducts.rejected, (state, action) => {
                state.isLoading = false;
            });
    }
});



export const { setShopifyProducts, clearShopifyProducts } = shopifyProductsSlice.actions;


export default shopifyProductsSlice.reducer;


