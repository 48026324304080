import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClientOrderDetails } from "../../../store/orders/ordersSlice";
import LoadingSpinningCup from "../../../pages/loading/Loading";
import "./ClientOrderBilling.css";

const ClientOrderBilling = () => {
    const dispatch = useDispatch();
    const customerDetails = useSelector(state => state.shopifyUser.customerDetails);
    const orderDetails = useSelector(state => state.orders?.customerOrderDetails);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (customerDetails.id) {
            setLoading(true);
            dispatch(getClientOrderDetails(customerDetails.id))
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [dispatch, customerDetails.id]);

    if (loading) {
        return <LoadingSpinningCup />;
    }

    if (!orderDetails || !orderDetails.orders || !orderDetails.orders.edges.length) {
        return <p>No orders found.</p>;
    }

    return (
        <div className="order-billing-container">
            {orderDetails.orders.edges.map((orderEdge, index) => {
                const order = orderEdge.node;
                return (
                    <div key={order.id} className="order-row">
                        <div className="order-header">
                            <div>{new Date(order.createdAt).toLocaleString()}</div>
                            <div>{order.name}</div>
                            <div>{order.email}</div>
                        </div>
                        <div className="shipping-address">
                            {order.shippingAddress.company && <><br />{order.shippingAddress?.company}</>}
                            {order.shippingAddress.firstName} {order.shippingAddress?.lastName}<br />
                            {order.shippingAddress.address1}<br />
                            {order.shippingAddress.address2 && <>{order.shippingAddress?.address2}<br /></>}
                            {order.shippingAddress.city}, {order.shippingAddress?.country}
                        </div>
                        {/*<div className="billing-address">*/}
                        {/*    {order.billingAddress.company && <><br />{order.billingAddress?.company}</>}*/}
                        {/*    {order.billingAddress.firstName} {order.billingAddress?.lastName}<br />*/}
                        {/*    {order.billingAddress.address1}<br />*/}
                        {/*    {order.billingAddress.address2 && <>{order.billingAddress?.address2}<br /></>}*/}
                        {/*    {order.billingAddress.city}, {order.billingAddress?.country}*/}
                        {/*</div>*/}
                        <table className="line-items-table">
                            <tbody>
                            {order.lineItems.edges.map((itemEdge, itemIndex) => {
                                const item = itemEdge.node;
                                return (
                                    <tr key={itemIndex}>
                                        <td>{item.title}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.price.amount} {item.price.currencyCode}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <div className="order-summary">
                            <div>Total Shipping: {order.totalShipping.amount} {order.totalShipping.currencyCode}</div>
                            <div>Total Price: {order.totalPrice.amount} {order.totalPrice.currencyCode}</div>
                            <div>Payment Status: {order.paymentInformation.paymentStatus}</div>
                            {order.paymentInformation.paymentCollectionUrl && (
                                <div>
                                    <a href={order.paymentInformation.paymentCollectionUrl} target="_blank" rel="noopener noreferrer">Pay Now</a>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ClientOrderBilling;
