// freddy-react/src/store/autobot/screeners/screenersSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    fetchAllClientDetails,
    fetchOrganizationLaboratoryAccountDetails,
    getAllClientAccounts
} from "../../services/api/v1/clientService";

const initialState = {
    clientData: [],
    isLoading: false,
    clientAccountDetails: null,
    error: null,
    isAccountNumbersVisible: false,
    isAccountsModalVisible: false,

};


export const getOrganizationLaboratoryAccountDetails = createAsyncThunk(
    'clients/getClientLaboratoryAccountDetails',
    async (organizationId, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await fetchOrganizationLaboratoryAccountDetails(organizationId, accessToken);
    }
);

export const fetchAllClientAccounts = createAsyncThunk(
    'clients/getAllClientAccounts',
    async (clientId, thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        return await getAllClientAccounts(clientId, accessToken);
    }
);

export const getAllClientDetails = createAsyncThunk(
    'clients/getAllClientDetails',
    async (params, thunkAPI) => {
        console.log("getting token from session storage")
        const accessToken = sessionStorage.getItem("accessToken");
        // console.log("fetchScreens: ", { params });
        const response = await fetchAllClientDetails(params, accessToken); // Adjust fetchScreens to accept filters
        // Include offset in the payload to distinguish between initial load and subsequent loads
        return { clients: response, offset: params.offset };
    }
);

export const getAllClients = createAsyncThunk(
    'clients/getAllClients',
    async (params, thunkAPI) => {
        console.log("getting token from session storage")
        const accessToken = sessionStorage.getItem("accessToken");
        console.log("fetchScreens: ", { params });
        return await fetchAllClientDetails(params, accessToken);
    }
);


const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        clearClients: (state) => {
            state.clientData = null;
        },
        setClients: (state, action) => {
            state.clientData = action.payload;

        },
        selectClients: (state, action) => {
            state.clientData = action.payload;
        },
        appendNewClients: (state, action) => {
            state.clientData = [...state.clientData, ...action.payload];
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        toggleAccountNumbersVisibility: state => {
            state.isAccountNumbersVisible = !state.isAccountNumbersVisible;
        },
        toggleClientAccountsModal: (state, action) => {
            state.isAccountsModalVisible = typeof action.payload === 'boolean' ? action.payload : !state.isModalVisible;
        }
    },
    extraReducers: {
        [getAllClientDetails.pending]: (state) => {
            state.isLoading = true;
            state.error = null;
        },
        [getAllClientDetails.fulfilled]: (state, action) => {
            const isIterable = action.payload != null && typeof action.payload[Symbol.iterator] === 'function';
            const clientData = isIterable ? action.payload : action.payload.clients || [];
            state.clientData = [...state.clientData, ...clientData];
            state.isLoading = false;
        },
        [getAllClientDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        },
        [getOrganizationLaboratoryAccountDetails.fulfilled]: (state, action) => {
            state.clientAccountDetails = action.payload; // Assuming the payload contains the account details
        },
        [getOrganizationLaboratoryAccountDetails.rejected]: (state, action) => {
            state.clientAccountDetails = null;
        },
        [getAllClients.fulfilled]: (state, action) => {
            const isIterable = action.payload != null && typeof action.payload[Symbol.iterator] === 'function';
            const clientData = isIterable ? action.payload : action.payload.clients || [];
            state.clientData = [...state.clientData, ...clientData];
            state.isLoading = false;
        },
        [getAllClients.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        },
        [fetchAllClientAccounts.fulfilled]: (state, action) => {
            state.clientAccountsDetails = action.payload;
        },
        [fetchAllClientAccounts.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        },
    }
});

export const { setLoading, setError, appendNewClients, setClients, selectClients, toggleAccountNumbersVisibility, toggleClientAccountsModal } = clientsSlice.actions;
export default clientsSlice.reducer;
