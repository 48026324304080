import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PanelsPage from "../pages/panels/Panels";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/user/login/Login";
import ShopifyHomePage from "../pages/shopify_home/ShopifyHome";

const UnauthenticatedRoutes = ({ instance, hostname }) => {
    return (
        <BrowserRouter>
            <Routes>
                {hostname === "fusion.labb.com" ? (
                    <Route path="*" element={<PanelsPage />} />
                ) : (
                    <>
                        <Route path="login" element={<LoginPage instance={instance} />} />
                        <Route path="panels" element={<PanelsPage />} />
                        <Route path="home" element={<ShopifyHomePage instance={instance} />} />
                        <Route path="*" element={<HomePage instance={instance} />} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default UnauthenticatedRoutes;
