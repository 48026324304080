import './PublicHeader.css';
import React, { useEffect, useState } from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { getUserRolesFromToken } from "../../helpers/token/processToken";
import Icon from "../header/nav/icons/Icon";
import { PrimaryButton } from "../buttons/primary_button/PrimaryButton";
import { SecondaryButton } from "../buttons/secondary_button/SecondaryButton";
import {getShopifyOAuthUrl} from "../user/sign_in/SignIn";
import UserIconCard from "../user/icon_card/IconCard";

const PublicHeader = ({onLogout }) => {
    const handleCollectorLogin = async () => {
        const hostname = window.location.hostname;
        const url = await getShopifyOAuthUrl(hostname);
        window.location.href = url;
    };


    return (
        <header className="main-header">
            <NavLink to="/" className="nav-button">
                <img className="header-logo"
                     src="https://static.wixstatic.com/media/40f727_b17ccfdda56f41e494a6586affdeae2e~mv2.png"
                     alt="Labb Logo"/>
            </NavLink>
            {/*<div className="nav-items">*/}
            {/*    <div className="nav-item">*/}
            {/*        <Icon iconSrc={Icons.PanelsIcon} routePath="/panels"/>*/}
            {/*    </div>*/}
            {/*</div>*/}


            {/* User icon card */}
            <UserIconCard handleCollectorLogin={handleCollectorLogin} onLogout={onLogout} />

        </header>
    );
};

export default PublicHeader;
